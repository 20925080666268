import safeProxyFactoryAbi from './abis/safeProxyFactoryAbi.json'
import safeSingletonAbi from './abis/safeSingletonAbi.json'
import multiSendAbi from './abis/multiSendAbi.json'
import multiSendCallOnlyAbi from './abis/multiSendCallOnlyAbi.json'
import fallbackHandlerAbi from './abis/fallbackHandlerAbi.json'
import signMessageLibAbi from './abis/signMessageLibAbi.json'
import createCallAbi from './abis/createCallAbi.json'
import simulateTxAccessorAbi from './abis/simulateTxAccessorAbi.json'

export const contractNetworks = {
  [686868]: {
    safeSingletonAddress: '0x7479c8be2cb59813c1696d4ab8Ab574605DB36bE',
    safeProxyFactoryAddress: '0x33539b6B3B521fDd25eE5112552f56A24b87F33f',
    multiSendAddress: '0x8AA155Bd561Ff636a729362767E73b855eBF575c',
    multiSendCallOnlyAddress: '0xAd2FC07fEfccbACD6433F27Fd3fA70E318e4f687',
    fallbackHandlerAddress: '0xB96810C379e1ab89C97265EA450DE4FaD320FAB3',
    signMessageLibAddress: '0x21e9f28f769A219754B0Dd83Ef29c0155A2a56Ec',
    createCallAddress: '0x147E227890EA8A164BaaFE17a65767846AA43489',
    simulateTxAccessorAddress: '0x27A5f29189AbAcf8f5b61785bE49874879e4e9B9',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [4200]: {
    safeSingletonAddress: '0x7479c8be2cb59813c1696d4ab8Ab574605DB36bE', //Safe L2
    safeProxyFactoryAddress: '0x33539b6B3B521fDd25eE5112552f56A24b87F33f',
    multiSendAddress: '0x8AA155Bd561Ff636a729362767E73b855eBF575c',
    multiSendCallOnlyAddress: '0xAd2FC07fEfccbACD6433F27Fd3fA70E318e4f687',
    fallbackHandlerAddress: '0xB96810C379e1ab89C97265EA450DE4FaD320FAB3', //CompatibilityFallbackHandler
    signMessageLibAddress: '0x21e9f28f769A219754B0Dd83Ef29c0155A2a56Ec',
    createCallAddress: '0x147E227890EA8A164BaaFE17a65767846AA43489',
    simulateTxAccessorAddress: '0x27A5f29189AbAcf8f5b61785bE49874879e4e9B9',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [223]: {
    safeSingletonAddress: '0xc9b9eeC1ca0eC0Dc38b85bA6d9CB5128729e41cE', //Safe L2
    safeProxyFactoryAddress: '0x340CDCEe987c1dE2dfAF70Ade05c28577B1b714c',
    multiSendAddress: '0x17211BDd630F2648B18bf62f9C1C01a02386F297',
    multiSendCallOnlyAddress: '0xcbBc266839870319498579162419f044e5A2f00c',
    fallbackHandlerAddress: '0x13ab224bAc2b282e740c529F5a472725a094E591', //CompatibilityFallbackHandler
    signMessageLibAddress: '0xaD0CD6780F1B5B5F04A73e21F6b7e0EF6067E69d',
    createCallAddress: '0xB65f47C50BE2338d12549C3992065a3fb65442F2',
    simulateTxAccessorAddress: '0x5F5ab67Da677B987DFf70A9b18F97a2a7Dcab56B',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [1115]: {
    safeSingletonAddress: '0x357Fc791B2f4daCB1C908e81A9a14bf9fE4Bff07', //Safe L2
    safeProxyFactoryAddress: '0x5C1639dDac1895C32947397D077777350f5F025E',
    multiSendAddress: '0x1da80a2e23681bD1E8207cEE8F2782443E21F523',
    multiSendCallOnlyAddress: '0x8f42adbF974c248BA604f7cBB213836933D9125A',
    fallbackHandlerAddress: '0x5C0A508CFD07a0e5B3b8a45A26828A0f46BbB5f9', //CompatibilityFallbackHandler
    signMessageLibAddress: '0x758fBDA491fCc35BE25B337CF64e1897b054dB5C',
    createCallAddress: '0x78e80edE538cfc5d652813c41Fa298EAb69F57db',
    simulateTxAccessorAddress: '0x51956e77F41E6c64EfC29b7874c385bdD6fc01B4',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [1116]: {
    safeSingletonAddress: '0xc9b9eeC1ca0eC0Dc38b85bA6d9CB5128729e41cE', //Safe L2
    safeProxyFactoryAddress: '0x340CDCEe987c1dE2dfAF70Ade05c28577B1b714c',
    multiSendAddress: '0x17211BDd630F2648B18bf62f9C1C01a02386F297',
    multiSendCallOnlyAddress: '0xcbBc266839870319498579162419f044e5A2f00c',
    fallbackHandlerAddress: '0x13ab224bAc2b282e740c529F5a472725a094E591', //CompatibilityFallbackHandler
    signMessageLibAddress: '0xaD0CD6780F1B5B5F04A73e21F6b7e0EF6067E69d',
    createCallAddress: '0xB65f47C50BE2338d12549C3992065a3fb65442F2',
    simulateTxAccessorAddress: '0x5F5ab67Da677B987DFf70A9b18F97a2a7Dcab56B',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
  [3636]: {
    safeSingletonAddress: '0x4DcB3d42808aEA9c7a9D0240a32589B481Ebc43B', //Safe L2
    safeProxyFactoryAddress: '0x8368C2BaE2f6c5b152E27FD1512543db46525f72',
    multiSendAddress: '0x92dfAfD80C10B78edbE037908dEc3C7d111f4187',
    multiSendCallOnlyAddress: '0x389936e61224A2426fb45A938Cf6FB54dB30a027',
    fallbackHandlerAddress: '0x3f4A6647e646ecEC34D943E4DE65c0fdcF7eb495', //CompatibilityFallbackHandler
    signMessageLibAddress: '0xAbb7aC97ee838c8E3FAeE89C20Ba3b32BF762a02',
    createCallAddress: '0x6d403Cc64a545DfB019681750C7E056E011F10cB',
    simulateTxAccessorAddress: '0x973EC70Ae2F6D518dD10F60C63f1B20aC7E2a66E',
    safeSingletonAbi,
    safeProxyFactoryAbi,
    multiSendAbi,
    multiSendCallOnlyAbi,
    fallbackHandlerAbi,
    signMessageLibAbi,
    createCallAbi,
    simulateTxAccessorAbi,
  },
}
